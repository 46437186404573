@use "../../ui-kit/scss/variables.scss" as *;
@use "../../ui-kit/scss/classes.scss" as *;

.main-content {
  margin-top: $margin-top-title;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 40px;
}

.button-flex {
  max-width: 200px;
}

.main-text-symbol-image {
  max-width: 50px;
}

.img-currency {
  max-width: 30px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 50px;
  margin-bottom: 50px;
}

.table td {
  vertical-align: middle;
  padding: 20px 30px;
  font-size: 22px;
  background-color: rgb(245,245,255);
  border-bottom: 2px solid rgb(255,255,255);
}