.gird-item {
  padding: 30px;
  border-radius: 10px;
  background-color: white;
}

.gird-item-balance {
  padding: 30px;
  border-radius: 10px;
  background-color: rgb(106,86,208);
  color: white;
}

.grid-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}


@media (max-width: 1140px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

.amount-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-currency {
  height: 30px;
}

.amount {
  margin-top: 15px;
  font-size: 20px;
}

.title-balance {
  color: white;
}

.balance {
  color: white;
  font-weight: 600;
  margin-top: 15px;
  font-size: 26px;
}

.btn-exchange {
  text-align: center;
  padding: 10px;
  border: 1px solid white;
  border-radius: 15px;
  color: white;
  margin-top: 20px;
  transition: background 0.2s ease;
}

.btn-exchange:hover {
  color: rgb(106,86,208);
  background-color: white;
  font-weight: 600;
  cursor: pointer;
}

.btn-withdrawal {
  text-align: center;
  color: rgb(106,86,208);
  border-radius: 15px;
  background-color: white;
  padding: 10px;
  border: 1px solid white;
  margin-top: 10px;
  font-weight: 600;
  transition: background 0.2s ease;
}

.btn-withdrawal:hover {
  background-color: rgb(245,245,255);
  cursor: pointer;
}

.grid-height {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.btn-withdrawal-crypto {
  text-align: center;
  color: rgb(106,86,208);
  border-radius: 15px;
  background-color: white;
  padding: 10px;
  border: 1px solid rgb(106,86,208);
  margin-top: 30px;
  font-weight: 600;
  transition: background 0.2s ease;
}

.btn-withdrawal-crypto:hover {
  background-color: rgb(245,245,255);
  cursor: pointer;
}



