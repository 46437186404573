
.dashboard-container {
  padding: 30px;
}

.dashboard-title {
  font-size: 30px;
  font-weight: 600;
}

.table-admin {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.table-admin td {
  padding: 10px;
  border: 1px solid rgb(245,245,245);
}