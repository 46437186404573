@use "../../scss/variables.scss" as *;

.body {
  position: relative;
  width: 100%;
}

.label {
  position: absolute;
  pointer-events: none;
  user-select: none;
  cursor: text;
  top: 17px;
  left: 12px;

  font-size: 16px;
  line-height: 19px;
  color: rgb(150,150,150);

  transition: all ease-out 0.05s;
}

.input {
  background-color: transparent;
  border-radius: 0px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 18px;
  padding-left: 12px;
  border: 1px solid rgb(200,200,200);
  

  &::placeholder {
    color: rgb(150,150,150);
    font-weight: 500;
  }

  &--dynamic-label {
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 17px;

      & ~ .label {
        top: 2px;

        font-size: 12px;
        line-height: 15px;
        color: rgb(150,150,150);
      }
    }
  }
}
