@use "../../ui-kit/scss/variables.scss" as *;
@use "../../ui-kit/scss/classes.scss" as *;


.main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-image {
  max-width: 500px;
}

.main-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.main-text-1 {
  font-size: 45px;
  font-weight: 300;
}

.main-text-2 {
  font-size: 45px;
  margin-top: 15px;
  font-weight: 600;
}

.main-text-3 {
  font-size: 45px;
  margin-top: 15px;
  font-weight: 300;
}

.main-text-4 {
  font-size: 18px;
  margin-top: 35px;
}



.main-currencies-list {
  margin-top: 40px;
  margin-bottom: 40px;
}

.img-currency {
  max-height: 30px;
  max-width: 30px;
}

.main-currencies-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

.main-text-symbol-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.main-text-symbol-right {
  margin-left: 5px;
  font-weight: 400;
  color: rgb(150,150,150);
}

.main-button {
  display: inline-block;
  padding: 15px 25px;
  border-radius: 30px;
  font-size: 16px;
  border: 2px solid rgb(100,100,100);
  transition: background 0.4s ease;
}

.main-button:hover {
  background-color: rgb(0,0,0);
  color: white;
}

.dashboard-flex-stat {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

@media (max-width: 1140px) {
  .dashboard-flex-stat {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
}

.dashboard-container {
  padding: 30px;
}

.dashboard-title {
  font-size: 30px;
  font-weight: 600;
}

.dashboard-flex-stat {
  margin-top: 30px;
  margin-bottom: 30px;
}

.dashboard-flex-create-bill {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 1140px) {
  .dashboard-flex-create-bill {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
}
