$green: rgb(64,85,220);
$green-hover: rgb(47, 66, 188);
$green-active: rgb(45, 62, 174);
$green-text: rgb(64,85,220);

$red: #d43f32;

$black-bg: rgb(255,255,255);
$black-element-bg: #303445;
$black-element-bg-hover: #3e445480;

$dark-gray: #677481;

$gold: #fcc958;
$gold-hover: #ffb40d;

$margin-top-title: 50px;