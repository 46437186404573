
.dashboard-container {
  padding: 30px;
}

.dashboard-title {
  font-size: 30px;
  font-weight: 600;
}

.project-list {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
}

.project-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-title {
  font-size: 22px;
}

.project-title-item {
  font-size: 18px;
  font-weight: 600;
}

.project-button {
  background-color: rgb(74,64,198);
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
}

.project-button-item {
  color: rgb(74,64,198);
  border: 1px solid rgb(74,64,198);
  padding: 10px 30px;
  border-radius: 20px;
}


.api-block {
  background-color: rgb(245,245,255);
  padding: 10px;
  margin-top: 15px;
}

.item-input-title-api {
  margin-top: 12px;
  margin-bottom: 12px;
}

.item-input-title-api-text {

}

.project-title-item-under {
  font-size: 16px;
  margin-top: 10px;
}