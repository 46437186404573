.app {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

@media (max-width: 768px) {
  .app {
    padding: 0 16px;
  }
}

.dashboard {
  display: flex;
  min-height: 100%;
}

@media (max-width: 768px) {
  .dashboard {
    display: block;
  }
}


.main-contant {
  background-color: rgb(245,245,245);
  width: calc(100% - 300px);
  min-height: 100%;
}

@media (max-width: 1140px) {
  .main-contant {
    background-color: rgb(245,245,245);
    width: calc(100% - 200px);
    min-height: 100%;
  }
}

@media (max-width: 768px) {
  .main-contant {
    background-color: rgb(245,245,245);
    width: 100% ;
    min-height: 100%;
  }
}


