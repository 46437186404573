.side-bar {
  width: 300px;
  padding-top: 30px;
  padding-left: 15px;
  padding-bottom: 30px;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-profile {
  padding: 12px;
  text-align: center;
  width: 46px;
  height: 46px;
  border-radius: 40px;
  background-color: rgb(243,243,249);
  margin-right: 10px;
  font-size: 20px;
  font-weight: 800;
  color: rgb(66,66,104);
}

.logo-kassa {
  font-size: 16px;
  font-weight: 800;
  color: rgb(66,66,104);
}

.logo-profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}


.hidden-menu {
  position: absolute;
  padding: 15px;
  background-color: rgb(255,255,255);
  width: 200px;
  margin-left: 200px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.menu {
  margin-top: 35px;
}

.logo-kassa-img {
  max-width: 100px;
}

@media (max-width: 1140px) {
  .side-bar {
    width: 200px;
    padding-left: 0px;
  }  

}

@media (max-width: 768px) {
  .side-bar {
    width: 100%;
    padding: 15px;
  }  
}

.under-menu {
  margin-top: 15px;
  padding-top: 25px;
  border-top: 1px solid rgb(220,200,245);
}


.link-under-menu {
  margin-bottom: 5px;
  color: blue;
  font-size: 13px;
}

.menu-mobile-item {
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;
  display:flex;
  align-items: center;
  justify-content: flex-start;
}

.menu-item-icon {
  width: 30px;
}



@media (max-width: 768px) {
  .menu {
    display: none;
  }  
}