@use "../../ui-kit/scss/variables.scss" as *;
@use "../../ui-kit/scss/classes.scss" as *;

.main-content {
  margin-top: $margin-top-title;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 40px;
}

.button-flex {
  max-width: 200px;
}

.input {
  & + & {
    margin-top: 12px;
  }
}

.block-data {
  padding: 50px;
  background-color: rgb(245,251,255);
  max-width: 800px;
}

.block-data-div {
  margin-top: 10px;
}

.block-data-div-more {
  margin-top: 25px;
}