@use "../../ui-kit/scss/variables.scss" as *;
@use "../../ui-kit/scss/classes.scss" as *;


.main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-image {
  max-width: 500px;
}

.main-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.main-text-1 {
  font-size: 45px;
  font-weight: 300;
}

.main-text-2 {
  font-size: 45px;
  margin-top: 15px;
  font-weight: 600;
}

.main-text-3 {
  font-size: 45px;
  margin-top: 15px;
  font-weight: 300;
}

.main-text-4 {
  font-size: 18px;
  margin-top: 35px;
}

.main-text-4 p {
  margin-top: 20px;
}

.main-text-4 ul {
  margin-top: 20px;
}

.main-currencies-list {
  margin-top: 40px;
  margin-bottom: 40px;
}

.img-currency {
  max-height: 30px;
  max-width: 30px;
}

.main-currencies-list {
  display: flex;
  align-items: center;
}

.main-text-symbol-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.main-text-symbol-right {
  margin-left: 5px;
  font-weight: 400;
  color: rgb(150,150,150);
}

.main-button {
  display: inline-block;
  padding: 15px 25px;
  border-radius: 30px;
  font-size: 16px;
  border: 2px solid rgb(100,100,100);
  transition: background 0.4s ease;
}

.main-button:hover {
  background-color: rgb(0,0,0);
  color: white;
}

.main-button-container {
  margin-top: 50px;
}


.text {
  margin-top: 50px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 50px;
}

.h2 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ul {
  list-style: circle;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}

strong {
  font-weight: 600;
}

.page-text strong {
  font-weight: 600;
}

.page-text .a {
  color: rgb(100,100,255);
}

.text-center {
  text-align: center;
}

h2 {
  font-size: 22px;
  font-weight: 600;
}