@use "../../ui-kit/scss/variables.scss" as *;

.alert_danger {
    position: fixed;
    right: 20px;
    bottom: 20px;
    min-width: 300px;
    max-width: 100%;
    text-align: center;
    border-radius: 8px;
    color: white;
    opacity: 0.9;
    z-index: 1000;
}