
.modal {
  font-family: 'Gilroy';
}

.modal-undertitle {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid rgb(235,235,235);
  border-radius: 10px;
}

.modal-table-container {
  background-color: rgb(245,245,245);
  padding: 15px;
}

.modal-table {
  width: 100%;
}

.footer-modal-bill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(220,220,220);
  background-color: rgb(245,245,245);
  border-radius: 5px;
  padding: 10px;
}

.container-left-1 {
  width: 100%;
  margin-top: 20px;
  background-color: rgb(255,255,255);
  padding: 30px;
  border-radius: 10px;
  box-shadow:3px 3px 3px rgb(235,235,235);
}

.title-type-payment {
  font-size: 16px;
}

.choosed-payment-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 10px;
  border: 1px solid rgb(106,86,208);
  border-radius: 10px;
}



.payment-left {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.img-currency {
  height: 24px;
}

.data-symbol {
  padding-left: 20px;
  font-size: 16px;
}

.data-fa {
  padding-left: 20px;
}

.payment-right {
  font-size: 16px;
}

.select-currency {
  z-index: 1000;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  border-radius: 10px;
  height: 200px;
  overflow:auto;
}


.currency-choose {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(245,245,245);
  cursor: pointer;
}

.currency-choose:hover {
  background-color: rgb(245,245,255);
}

.currency-choose div:nth-child(1) {
  padding-right: 15px;
}

.currency-choose div:nth-child(2) {
  font-size: 16px;
}



.button {
  background-color: rgb(74,64,198);
  color: white;
  padding: 12px 40px;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(60,54,178);
}

.amount-balance {
  text-align: left;
  margin-top: 10px;
  color: rgb(100,100,100);
  font-size: 14px;
}

.amount-balance-span {
  color: black;
  font-weight: 600;
  cursor: pointer;
}


.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}


.title {
  flex: 0 0 auto;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 30px;

}

.form {
  padding: 50px;
  background-color: rgb(245,251,255);
  max-width: 500px;
  margin: 0 auto;
}

.input {
  & + & {
    margin-top: 12px;
  }
}

.change-data {
  color: rgb(60,54,178);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  text-align: left;
}

.code-title {
  text-align: left;
  margin-top: 10px;
}

.payment-info {
  margin-top: 35px;
}

.payment-info div {
  color: rgb(50,50,50);
  margin-bottom: 10px;
}
