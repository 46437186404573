@use "../../ui-kit/scss/variables.scss" as *;

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background-color: rgb(245,245,245);
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px;
}

.button {
  margin-top: 40px;
  margin-bottom: 40px;
}

.close-button {
  align-self: flex-end;
}

.languages {
}
