.widget-stat {
  
  background-color: white;
  padding: 30px 20px;
  border-radius: 10px;
  color: rgb(50,50,50);

}

.widget-stat .title {
  font-size: 16px;
}

.widget-stat .value {
  font-size: 28px;
  margin-top: 10px;
  font-weight: 600;
}

.widget-stat .value-under {
  margin-top: 8px;
  font-size: 20px;
}


.widget-stat-link {
  background-color: rgb(106,86,208);
  padding: 30px 20px;
  border-radius: 10px;
  color: white;
  border: 3px solid rgb(106,86,208);
}

.widget-stat-link .title {
  font-size: 16px;
  color: white;
}

.widget-stat-link .value {
  font-size: 28px;
  margin-top: 10px;
  font-weight: 600;
  color: white;
}

.widget-stat-link .value-under {
  margin-top: 8px;
  font-size: 20px;
  color: white;
}