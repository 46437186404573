@use "../../ui-kit/scss/variables.scss" as *;
@use "../../ui-kit/scss/classes.scss" as *;

.main-content {
  margin-top: $margin-top-title;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 40px;
}

.button-flex {
  max-width: 200px;
}