.container {
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid rgb(74,64,198);
}

.input {
  & + & {
    margin-top: 12px;
  }
}


.select {
  & + & {
    margin-top: 12px;
  }

  background-color: transparent;
  border-radius: 0px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 18px;
  padding-left: 12px;
  border: 1px solid rgb(200,200,200);
  
}


.item-input {
  margin-top: 25px;
}

.item-input-title {
  margin-bottom: 5px;
  color: rgb(100,100,100);
}

.block-title {
  font-size: 18px;
  font-weight: 600;
}


.button {
  background-color: rgb(74,64,198);
  color: white;
  padding: 12px 40px;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(60,54,178);
}


.modal {
  font-family: 'Gilroy';
}

.modal-undertitle {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid rgb(235,235,235);
  border-radius: 10px;
}

.modal-table-container {
  background-color: rgb(245,245,245);
  padding: 15px;
}

.modal-table {
  width: 100%;
}

.footer-modal-bill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(220,220,220);
  background-color: rgb(245,245,245);
  border-radius: 5px;
  padding: 10px;
}

.footer-modal-bill a {
  color: blue;
}

.modal-table tr td:nth-child(1) {
  text-align: left;
}


.modal-table tr td:nth-child(2) {
  text-align: right;
}

.modal-table td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}