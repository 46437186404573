.container {
  padding: 30px;
  border-radius: 10px;
  background-color: white;
}


.block-title {
  font-size: 18px;
  font-weight: 600;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-bills {
  font-size: 16px;
  margin-top: 15px;
}

.table-bill {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
  margin-top: 15px;
}

.table-bill td {
  padding: 15px 10px;
  border-bottom: 1px solid rgb(245,245,255);
  border-top: 1px solid rgb(245,245,255);
  font-size: 14px;
  background-color: rgb(245,245,255);
}

.table-bill .table-bill-thead td {
  background-color: white;
  font-weight: 500;
}


.new {
  background-color: rgb(50,50,200);
  color: white;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.success {
  background-color: rgb(1, 118, 1);
  color: white;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.send_transition {
  background-color: rgb(1, 118, 1);
  color: white;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.send_comission {
  background-color: rgb(1, 118, 1);
  color: white;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.success_process {
  background-color: rgb(1, 118, 1);
  color: white;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.compare {
  background-color: rgb(1, 118, 1);
  color: white;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.success_process {
  background-color: rgb(1, 118, 1);
  color: white;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  border: 2px solid red;
}

.canceled {
  background-color: rgb(255, 157, 157);
  color: black;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.partial {
  background-color: rgb(15, 214, 228);
  color: black;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
}

.img-currency {
  height: 30px;
  width: 30px;
}

.table-bill-container {
  overflow:auto;
}



@media (max-width: 768px) {

  .new {
    padding: 5px;
    border-radius: 10px;
    font-size: 13px;
  }

  .success {
    padding: 5px;
    border-radius: 10px;
    font-size: 13px;
  }

  .canceled {
    padding: 5px;
    border-radius: 10px;
    font-size: 13px;
  }

  .partial {
    padding: 5px;
    border-radius: 10px;
    font-size: 13px;
  }

}