
.dashboard-container {
  padding: 30px;
}

.dashboard-title {
  font-size: 30px;
  font-weight: 600;
}

.setting-list {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
}


.button {
  background-color: rgb(74,64,198);
  color: white;
  padding: 12px 40px;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(60,54,178);
}

.title-not-isset {
  margin-bottom: 15px;
}

.title {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 15px;
}