
.menu {

  &-item {
    padding: 15px;
    color: rgb(66,66,104);
  }

  &-item-active {
    padding: 15px;
    color: rgb(66,66,104);
    background-color: rgb(243,243,249);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 4px solid rgb(74,64,198);
  }
}


.menu-item-sub {
  padding: 15px;
  padding-left: 45px;
  color: rgb(66,66,104);
}

.menu-item-sub-active {
  padding: 15px;
  padding-left: 45px;
  color: rgb(66,66,104);
  background-color: rgb(243,243,249);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 4px solid rgb(74,64,198);
}

.fa-icon {
  color: rgb(200,200,200);
  padding-right: 20px;
}

.menu-sub-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa {
  cursor: pointer;
}


@media (max-width: 1140px) {

    
  .menu {

    &-item {
      padding: 10px;
    }

    &-item-active {
      padding: 10px;
    }
  }


  .menu-item-sub {
    padding:10 px;
    padding-left: 10px;
  }

  .menu-item-sub-active {
    padding: 10px;
    padding-left: 10px;
  }

}

@media (max-width: 768px) {

}

