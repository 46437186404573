.container {
  padding: 30px;
  border-radius: 10px;
  background-color: white;
}

.block-title {
  font-size: 18px;
  font-weight: 600;
}

.item-input {
  margin-top: 25px;
}