

.container {
  width: 100%;
  background-color: rgb(245,245,255);
  min-height: 100%;
  padding-bottom: 100px;
}

.container-header {
  width: 100%;
  background-color: rgb(255,255,255);
  padding: 25px;
}

.logo {
  max-width: 100px;
}

.devider {
  padding-right: 80px;
  padding-left: 80px;
}

.devider span {
  font-size: 50px;
  font-weight: 300;
  color: rgb(245,245,245);
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 15px;
  max-width: 1000px;
  margin: 0 auto;
}

.container-left-1 {
  width: 100%;
  margin-top: 20px;
  background-color: rgb(255,255,255);
  padding: 30px;
  border-radius: 10px;
  box-shadow:3px 3px 3px rgb(235,235,235);
}

.container-left-2 {
  width: 100%;
  margin-top: 20px;
  background-color: rgb(255,255,255);
  padding: 30px;
  border-radius: 10px;
  box-shadow:3px 3px 3px rgb(235,235,235);
}

.container-left-3 {
  width: 100%;
  margin-top: 20px;
  background-color: rgb(255,255,255);
  padding: 30px;
  border-radius: 10px;
  box-shadow:3px 3px 3px rgb(235,235,235);
}

.container-right-1 {
  width: 100%;
  margin-top: 20px;
  background-color: rgb(106,86,208);
  color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow:3px 3px 3px rgb(207, 198, 255);
}

.container-right-2 {
  width: 100%;
  margin-top: 20px;
  background-color: rgb(106,86,208);
  color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow:3px 3px 3px rgb(207, 198, 255);
}

.inv-title {
  color: white;
  font-size: 22px;
  font-weight: 400;
}

.inv-time {
  color: white;
  margin-top: 7px;
}

.inv-amount-container {
  display: flex;
  align-items: center;
  justify-content: space-between;



  &-title {
    color: white;
    font-size: 20px;
  } 
  
  &-comission-title {
    color: white;
    font-size: 14px;
  } 

  &-title-small {
    color: white;
    font-size: 14px;
  } 
  
  &-symbol {
    color: white;
    font-size: 20px;
  }

  &-comission-symbol {
    color: white;
    font-size: 14px;
  }

  &-symbol-small {
    color: white;
    font-size: 14px;
  }
  
}

.project-name {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.inv-amount-bt {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgb(135, 124, 188);
  border-bottom: 1px solid rgb(135, 124, 188);
}

.button {
  padding: 12px 40px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  background-color: white;
  color: rgb(106,86,208);
  font-weight: 600;
}

.button:hover {
  background-color: rgb(245,245,245);
}

.agreement {
  font-size: 12px;
  color: white;
}

.agreement-container {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.title-type-payment {
  font-size: 22px;
}

.choosed-payment-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding: 20px;
  border: 1px solid rgb(106,86,208);
  border-radius: 10px;
}

.choosed-payment-container-step2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding: 20px;
  border: 1px solid rgb(220,220,220);
  border-radius: 10px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-left {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.img-currency {
  height: 30px;
}

.data-symbol {
  padding-left: 20px;
  font-size: 20px;
}

.data-fa {
  padding-left: 20px;
}

.payment-right {
  font-size: 20px;
}

.select-currency {
  z-index: 1000;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  border-radius: 10px;
}

.notification-email-container {
  display: flex;
  align-items: center;
}

.notification-email {
  font-size: 18px;
  padding-left: 10px;
}

.faq-title {
  font-size: 20px;
}

.p {
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgb(50,50,50);
}

.ul {
  margin-top: 10px;
  list-style: circle;
  margin-left: 15px;
}

.ul li {
  color: rgb(50,50,50);
  padding-top: 5px;
  padding-bottom: 5px;
}

.input {
  & + & {
    margin-top: 12px;
  }
}

.email-label {
  margin-top: 10px;
  font-size: 13px;
  margin-bottom: 5px;
}

.currency-choose {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(245,245,245);
  cursor: pointer;
}

.currency-choose:hover {
  background-color: rgb(245,245,255);
}

.currency-choose div:nth-child(1) {
  padding-right: 15px;
}

.currency-choose div:nth-child(2) {
  font-size: 16px;
}

.change-type-button {
  color: rgb(106,86,208);
  font-size: 13px;
  color: 14px;
  cursor: pointer;
}

.under-title {
  margin-top: 15px;
  margin-bottom: 15px;
  color: rgb(100,100,100);
}

.flex-amount {
  display: flex;
  align-items: center;
}

.flex-space-between-border {
  border: 1px solid rgb(230,230,255);
  padding: 15px;
  margin-top: 15px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  -webkit-box-shadow: 0 4px 6px -6px rgb(200,200,200);
  -moz-box-shadow: 0 4px 6px -6px rgb(200,200,200);
  box-shadow: 0 4px 6px -6px rgb(200,200,200);

}

.flex-amount div:nth-child(1) {
  width: 100px;
  color: rgb(100,100,100);
  font-size: 14px;
}

.flex-amount div:nth-child(2) {
  color: rgb(100,100,100);
  font-size: 14px;
}

.flex-amount div:nth-child(3) span {
  color: rgb(100,100,100);
  font-size: 14px;
}



.flex-payeer {
  display: flex;
  align-items: center;
  width: 100%;
}

.flex-payeer div:nth-child(1) {
  width: 100px;
  color: rgb(100,100,100);
  font-size: 14px;
}

.flex-payeer div:nth-child(2) {
  color: rgb(100,100,100);
  font-size: 14px;
  width: calc(100% - 100px);
}

.flex-payeer div:nth-child(2) div {
  width: 100%;
}

.payeer-form {
  margin-top: 15px;
}

.qrcode-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &-img {
    width: 180px;
  }

  &-text {
    padding-left: 20px;
    min-width: calc(100% - 180px);
  }

}

.final-container {
  display: flex;
  align-items: center;
  justify-content: left;
  
  &-img {
    width: 80px;
  }

  &-text {
    padding-left: 20px;
  }

}

.under-title-loader {
  margin-top: 15px;
  color: rgb(100,100,100);
}

.type-payment-block {
  margin-top: 15px;
}

.type-payment-block-item {
  padding: 10px 15px;
  border: 1px solid rgb(220,220,220);
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 13px;
}

.a-blue {
  color: blue;
  text-decoration: underline;
}


.process-payeer-btn {
  padding: 10px;
  background-color: rgb(106,86,208);
  color: white;
}