
.dashboard-container {
  padding: 30px;
}

.dashboard-title {
  font-size: 30px;
  font-weight: 600;
}


.dashboard-flex-stat {
  margin-top: 30px;
  margin-bottom: 30px;
}

.dashboard-flex-stat {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}

.dashboard-flex-create-bill {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}


@media (max-width: 1140px) {

  .dashboard-flex-create-bill {
    grid-template-columns: 1fr;
  }

  .dashboard-flex-stat {
    grid-template-columns: 1fr 1fr;
  }

}

@media (max-width: 768px) {
  
  .dashboard-flex-create-bill {
    grid-template-columns: 1fr;
  }

  .dashboard-flex-stat {
    grid-template-columns: 1fr 1fr;
  }

}
