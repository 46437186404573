
.dashboard-container {
  padding: 30px;
}

.dashboard-title {
  font-size: 30px;
  font-weight: 600;
}

.block {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
}

.block-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-stepper {
  font-size: 14px;
  color: rgb(100,100,100);
}

.block-title {
  font-size: 18px;
}

.grid-step-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}


.input {
  & + & {
    margin-top: 12px;
  }
}

.item-input {
  margin-top: 25px;
}

.item-input-title {
  margin-bottom: 5px;
  color: rgb(100,100,100);
}

.img-currency {
  height: 20px;
  width: 20px;
}

.table {
  margin-top: 20px;
}

.table td {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  vertical-align: middle;
}

.button {
  background-color: rgb(74,64,198);
  color: white;
  padding: 12px 40px;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(60,54,178);
}

.error {
  margin-top: 3px;
  font-size: 12px;
  color: red;
}

.api-block {
  margin-top: 30px;
  background-color: rgb(245,245,255);
  padding: 20px;
}

.item-input-title-api {
  margin-top: 20px;
  font-weight: 500;
}

.item-input-title-api-text {
  margin-top: 10px;
  margin-bottom: 20px;
}

.item-type_payment-title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 3px;
}